import { firstAndLastNameValidator, Validator } from '../../components/Validator';
import { PersonalInfoField, PersonalInfoState } from './personalInfoSlice';

const emailValidator = (value: string) =>
    /^[\x21-\x26\x28-\x7E]+@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(
        value,
    ) && value.length <= 73;

// country code +89x is not a valid country code
const optionalPhoneNumberValidator = (value: string) =>
    /^$|^\+[1-9][0-9 ]{5,20}$/.test(value) && !value.startsWith('+89') && !/\s{2,}/.test(value);

export const nameValidator = (value: string) => {
    const errors = [];

    if (!value || value.trim().length === 0) {
        errors.push('registration.error.name.WhitespaceOnly');
    } else if (!!value && value.length > 40) {
        errors.push('registration.error.name.maxNumCharacters');
    } else if (!firstAndLastNameValidator(value)) {
        errors.push('registration.error.name.notAllowedFormat');
    }
    return errors;
};

const isSelectedValidator = (value: boolean | null | undefined): boolean => !!value;

export const getFieldValidator = (fieldName: PersonalInfoField): Validator<any> | undefined => {
    switch (fieldName) {
        case 'lastName':
            return firstAndLastNameValidator;
        case 'firstName':
            return firstAndLastNameValidator;
        case 'email':
            return emailValidator;
        case 'phoneNumber':
            return optionalPhoneNumberValidator;
        case 'termsAccepted':
            return isSelectedValidator;
        default:
            return undefined;
    }
};

export const isPersonalInfoValid = (state: PersonalInfoState): boolean =>
    !!state.firstNameValid &&
    !!state.lastNameValid &&
    !!state.emailValid &&
    !!state.phoneNumberValid &&
    state.termsAccepted;
