import { FormattedMessage } from 'react-intl';
import { FirstNameFieldContainer, LastNameFieldContainer } from './elements/NameField';
import {
    NewsletterApprovedContainer,
    PersonalEmailContainer,
    PersonalPhoneNumberContainer,
    TermsAcceptedContainer,
} from './elements/PersonalElementContainers';
import { PersonalExplanation } from './elements/PersonalExplanation';
import { PersonalBackButton } from './elements/PersonalBackButton';
import { PersonalNextButton } from './elements/PersonalNextButton';
import { useSendRegistrationMutation } from '../../services/registrationApiService';
import { useEffect } from 'react';
import { useAppNavigate, useAppSelector } from '../../configuration/setup/hooks';
import { getRegistrationRequestBody } from '../../services/apiServiceUtils';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { FetchBaseQueryError, QueryStatus } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { Region, regionSelector } from '../region/regionSlice';
import { ROUTE_FINISHED } from '../app/routes/routes';

export const PersonalPage = () => {
    const navigate = useAppNavigate();

    const { region, companyInfo, personalInfo, displayLocale } = useAppSelector((state) => ({
        region: regionSelector(state),
        companyInfo: state.companyInfo,
        personalInfo: state.personalInfo,
        displayLocale: state.lang.displayLocale,
    }));

    const [sendRegistration, { isLoading, isSuccess, status, error }] = useSendRegistrationMutation();

    const finishRegistration = () => {
        const registrationBody = getRegistrationRequestBody(region, companyInfo, personalInfo, displayLocale);
        sendRegistration(registrationBody);
    };

    useEffect(() => {
        if (isSuccess) {
            navigate(ROUTE_FINISHED);
        }
    }, [isSuccess, navigate]);

    useEffect(() => {
        if (status === QueryStatus.pending) {
            Notification.info(<FormattedMessage id={'request.processing'} />);
        }
        if (status === QueryStatus.rejected) {
            displayErrorNotification(error, region);
        }
    }, [status, error, region]);

    return (
        <div className={'RegistrationPanelContainer panel-default panel panel-body padding-25'}>
            <h2 className={'text-center margin-bottom-20'}>
                <FormattedMessage id={'registration.personalData.heading'} />
            </h2>
            <form className={'PersonalPageContainer'}>
                <div className={'row'}>
                    <FirstNameFieldContainer width={'col-sm-6 margin-bottom-10'} fieldDisabled={isLoading} />
                    <LastNameFieldContainer width={'col-sm-6 margin-bottom-10'} fieldDisabled={isLoading} />
                </div>
                <div className={'row'}>
                    <PersonalEmailContainer
                        width={'col-sm-6 margin-bottom-10'}
                        error={'registration.error.email.InvalidFormat'}
                        fieldDisabled={isLoading}
                        required
                    />
                    <PersonalPhoneNumberContainer
                        width={'col-sm-6 margin-bottom-10'}
                        required={false}
                        fieldDisabled={isLoading}
                    />
                </div>
                <TermsAcceptedContainer className={'margin-top-10 margin-bottom-10'} disabled={isLoading}>
                    <PersonalExplanation />
                </TermsAcceptedContainer>
                <NewsletterApprovedContainer className={'margin-bottom-15'} disabled={isLoading} />
                <div className={'row margin-top-20-sm'}>
                    <PersonalBackButton isLoading={isLoading} />
                    <PersonalNextButton isLoading={isLoading} onClick={finishRegistration} />
                </div>
            </form>
        </div>
    );
};

const isFetchBaseQueryError = (v: FetchBaseQueryError | SerializedError): v is FetchBaseQueryError => {
    return (v as FetchBaseQueryError).data !== undefined && typeof (v as FetchBaseQueryError).data === 'string';
};

interface RegistrationError {
    code: string;
    detail: string;
    status: number;
}

const isRegistrationError = (v: unknown): v is RegistrationError => {
    return (
        (v as RegistrationError).code !== undefined &&
        (v as RegistrationError).detail !== undefined &&
        (v as RegistrationError).status !== undefined
    );
};

const isKnownErrorCode = (i: string): boolean => {
    const knownErrorCodes = [
        'DUPLICATE_TAX_ID',
        'GENERAL_DUPLICATE',
        'DUPLICATE_COMPANY_NAME',
        'COUNTRY_TAX_ID_MISMATCH',
        'USER_ALREADY_EXISTS',
        'SF_DUPLICATE_COMPANY_NAME',
        'SF_DUPLICATE_COMPANY_NAME_AND_DUPLICATE_ADDRESS',
    ];
    return knownErrorCodes.includes(i);
};

const displayErrorNotification = (
    error: FetchBaseQueryError | SerializedError | undefined,
    region: Region | undefined,
) => {
    let messageId = 'registration.error.backend.default';

    if (region === Region.latinamerica) {
        messageId = 'error.commonError.latinamerica';
    } else if (error && isFetchBaseQueryError(error)) {
        const data = JSON.parse(error.data as string);
        if (isRegistrationError(data)) {
            if (isKnownErrorCode(data.code)) {
                messageId = `registration.error.backend.${data.code}`;
            }
        }
    }

    Notification.error(<FormattedMessage id={messageId} />);
};
