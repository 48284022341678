import { BillingAddressAdditionalFields, RegistrationRequest, Tenant } from './apiTypes';
import { Region } from '../features/region/regionSlice';
import { CompanyInfoState, TaxEntityType } from '../features/companyInfo/companyInfoSlice';
import { config } from '../config';
import { PersonalInfoState } from '../features/personal/personalInfoSlice';

export const prepareHeaders = (headers: Headers) => {
    headers.set('Content-Type', 'application/json');
    return headers;
};

export const handleResponse = async (response: Response) => {
    if (response.ok) {
        if (response.status === 204) {
            return;
        }
        try {
            return response.json();
        } catch (error) {
            throw new Error(`${response.status} Invalid payload: ${error}`);
        }
    }
    if (response.status === 401) {
        throw new Error(`${response.status} Unauthorized: ${response.statusText}`);
    }
    throw new Error(`${response.status} Backend error: ${response.statusText}`);
};

const getTenant = (region: Region | undefined): Tenant => {
    const testTenantMode = config.isTestTenantMode;
    switch (region) {
        case Region.europe:
            return testTenantMode ? 'rio-eu.test' : 'rio-eu.prod';
        case Region.latinamerica:
            return testTenantMode ? 'rio-brazil.test' : 'rio-brazil.prod';
        default:
            throw new Error('Unknown tenant');
    }
};

const getTaxEntityType = (type: TaxEntityType) => {
    switch (type) {
        case TaxEntityType.VAT:
            return 'VAT';
        case TaxEntityType.LegalEntity:
            return 'CNPJ';
        case TaxEntityType.NaturalEntity:
            return 'CPF';
    }
};

export const getRegistrationRequestBody = (
    region: Region | undefined,
    companyInfo: CompanyInfoState,
    personalInfo: PersonalInfoState,
    displayLocale: string | undefined,
): RegistrationRequest => {
    const latam_billing_address_additional_fields: BillingAddressAdditionalFields | undefined =
        region === Region.latinamerica
            ? {
                  address_details: companyInfo.addressDetails,
                  city_id: companyInfo.cityId,
                  neighbourhood: companyInfo.neighborhood,
                  phone_number: companyInfo.phone.replaceAll(' ', ''),
                  state: companyInfo.state,
              }
            : undefined;

    return {
        user: {
            first_name: personalInfo.firstName,
            last_name: personalInfo.lastName,
            email: personalInfo.email,
            phone_number: personalInfo.phoneNumber ? personalInfo.phoneNumber.replaceAll(' ', '') : undefined,
            newsletter_approved: personalInfo.newsletterApproved,
            preferred_language: displayLocale ? displayLocale : 'en-GB',
        },
        account: {
            company_name: companyInfo.companyName,
            legal_address: {
                first_line: `${companyInfo.street} ${companyInfo.houseNo}`,
                second_line: '',
                third_line: '',
                city: companyInfo.city,
                postal_code: companyInfo.zipCode,
                country_code: companyInfo.countryCode,
            },
            tax_id: {
                value: companyInfo.vat,
                tax_type: getTaxEntityType(companyInfo.taxEntity),
            },
            latam_billing_address_additional_fields,
        },
        tenant: getTenant(region),
        registration_channel: 'self_registration_form',
    };
};
